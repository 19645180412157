import Vue from "vue";
import VueRouter from "vue-router";
import TokenManager from "../utils/TokenManager";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    beforeEnter: (to, from, next) => {
      TokenManager.getBosAdminToken() === "" ? next("login") : next();
    },
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    children: [
      {
        path: "",
        name: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../components/Dashboard.vue"
          )
      },
      {
        path: "banner",
        name: "banner",
        component: () =>
          import(
            /* webpackChunkName: "banner" */ "../views/banner/BannerManage.vue"
          )
      },
      {
        path: "banner/detail",
        name: "banner-detail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "banner-detail" */ "../views/banner/BannerDetail.vue"
          )
      },
      {
        path: "news",
        name: "news",
        component: () =>
          import(/* webpackChunkName: "news" */ "../views/news/NewsManage.vue")
      },
      {
        path: "news/detail",
        name: "news-detail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "news-detail" */ "../views/news/NewsDetail.vue"
          )
      },
      {
        path: "category",
        name: "category",
        component: () =>
          import(
            /* webpackChunkName: "category" */ "../views/category/CategoryManage.vue"
          )
      },
      {
        path: "category/detail",
        name: "category-detail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "category-detail" */ "../views/category/CategoryDetail.vue"
          )
      },
      {
        path: "product",
        name: "product",
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/product/ProductManage.vue"
          )
      },
      {
        path: "product/detail",
        name: "product-detail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "product-detail" */ "../views/product/ProductDetail.vue"
          )
      },
      {
        path: "cart",
        name: "cart",
        component: () =>
          import(/* webpackChunkName: "cart" */ "../views/cart/CartManage.vue")
      },
      {
        path: "cart/detail",
        name: "cart-detail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "cart-detail" */ "../views/cart/CartDetail.vue"
          )
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginPage.vue")
  },
  {
    path: "*",
    name: "notfound",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/NotFoundPage.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
