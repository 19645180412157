export default {
  getBosAdminToken: function() {
    const bosAdminToken = localStorage.getItem("BOS_A_TOKEN");
    return bosAdminToken && typeof bosAdminToken !== "undefined"
      ? bosAdminToken
      : "";
  },
  setBosAdminToken: function(bosAdminToken) {
    localStorage.setItem("BOS_A_TOKEN", bosAdminToken);
  },
  clearAllTokens: function() {
    localStorage.removeItem("BOS_A_TOKEN");
  }
};
